.settings_users_role_create_modal__button {
    border-radius: 100% !important;
    border-left-width: thin !important;

    &::before {
        display: none !important;
    }

}

.settings_users_role_create_modal__swich {
    display: flex !important;
    align-items: center;
}

.mr-10{
    margin-left: 10px;
}

.role_container {
    border-bottom: 1px solid #7676762e; 
    padding-bottom: 15px
}