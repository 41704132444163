
body{
    margin: 0;
}

.auth {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: 100%;
        z-index: -1;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        background-color: #fff;
        padding: 15px;
        border-radius: 16px;
        max-width: 610px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 400px) {
            padding: 32px;
        }
    }

    &__logo {
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__description {
        text-align: center;
        margin-bottom: 24px ;
    }

    &__form-item {
        margin-bottom: 15px !important;
        margin-top: 20px;

        @media (min-width: 400px) {
            margin-bottom: 24px !important;
        }
    }

    &__button {
        margin-top: 16px;
    }
}
.ant-layout-sider-trigger {
    position: absolute !important;
    height: 66px !important;
    width: 14px !important;
    right: -20px !important;
    top: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transform: translate(-50%) !important;
    clip-path: polygon(0px 0px, 100% 10px, 100% calc(100% - 10px), calc(100% - 16px) 100%, 0 100%, 0 0px);
}

.header{
    border-bottom: 1px solid #f1f1f1 !important;
}

.ant-breadcrumb {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.ant-breadcrumb > ol {
    align-items: center;
}

:where(.css-dev-only-do-not-override-zzwiog).ant-form-item {
    margin-bottom: 10px;
  }

.modal_fields_button {
    text-align: center;
    border: 1px solid #f5f5f5;
    transition: 0.4s;
    cursor: pointer;
}

.modal_fields_button:hover{
    border: 1px solid #1677ff;
}

.ql-container{
    height: calc(100% - 43px) !important;
}

.product_card {
    background-color: #fff;
    padding: 10px;

}


.is-dragging{
    position: relative;
    z-index: 99;
}

.table_pagination{
    justify-content: start !important;
}

.ant-pagination-options{
    position: absolute;
    right: 10px;

}