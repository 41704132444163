.preloader_body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

// ---


.preloader {
    color: #FFF;
    display: inline-block;
    position: relative;
    font-size: 30px;
    font-weight: 100;
}

.preloader::after {
    content: '';
    width: 3px;
    height: 3px;
    background: currentColor;
    position: absolute;
    bottom: 13px;
    right: -7px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
}

@keyframes animloader {
    0% {
        box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 10px 0 white, 20px 0 rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 10px 0 white, 20px 0 white;
    }
}