
.empty-custom {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ant-table-tbody>.ant-table-placeholder>.ant-table-cell {
    &:hover {
        background: transparent;
        height: 100%;
    }
}


