svg#freepik_stories-my-password:not(.animated) .animable {
    opacity:
        0;
}

svg#freepik_stories-my-password.animated #freepik--Floor--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-my-password.animated #freepik--Shadows--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

svg#freepik_stories-my-password.animated #freepik--Plants--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay:
        0s;
}

svg#freepik_stories-my-password.animated #freepik--Device--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay: 0s;
}

svg#freepik_stories-my-password.animated #freepik--Screen--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay:
        0s;
}

svg#freepik_stories-my-password.animated #freepik--Fingerprint--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-my-password.animated #freepik--face-recognition--inject-2 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

svg#freepik_stories-my-password.animated #freepik--Character--inject-2 {
    animation:
        1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
    animation-delay: 0s;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }

    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }

    60% {
        transform:
            skewX(10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(-2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


// RESTORE PASSWORD

svg#freepik_stories-forgot-password:not(.animated) .animable {
    opacity:
        0;
}

svg#freepik_stories-forgot-password.animated #freepik--Floor--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

svg#freepik_stories-forgot-password.animated #freepik--Shadow--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay:
        0s;
}

svg#freepik_stories-forgot-password.animated #freepik--Plants--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay:
        0s;
}

svg#freepik_stories-forgot-password.animated #freepik--speech-bubble--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-forgot-password.animated #freepik--question-marks--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay: 0s;
}

svg#freepik_stories-forgot-password.animated #freepik--Character--inject-3 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay: 0s;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }

    60% {
        transform:
            skewX(10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(-2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

// 404

svg#freepik_stories-oops-404-error-with-a-broken-robot:not(.animated) .animable {
    opacity:
        0;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--Floor--inject-22 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay:
        0s;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--Shadows--inject-22 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay:
        0s;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--Gears--inject-22 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay:
        0s;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--Robot--inject-22 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay:
        0s;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--error-404--inject-22 {
    animation:
        1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay:
        0s;
}

svg#freepik_stories-oops-404-error-with-a-broken-robot.animated #freepik--speech-bubble--inject-22 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }

    60% {
        transform:
            skewX(-10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }

    100% {
        opacity: 1;
        transform:
            translateY(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.animator-hidden {
    display: none;
}

// Maintance

svg#freepik_stories-under-construction:not(.animated) .animable {
    opacity:
        0;
}

svg#freepik_stories-under-construction.animated #freepik--Floor--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay:
        0s;
}

svg#freepik_stories-under-construction.animated #freepik--Shadows--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Gears--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
    animation-delay:
        0s;
}

svg#freepik_stories-under-construction.animated #freepik--Cones--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Plant--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Window--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
    animation-delay:
        0s;
}

svg#freepik_stories-under-construction.animated #freepik--Crane--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Barrier--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedRight;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Character--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
    animation-delay:
        0s;
}

svg#freepik_stories-under-construction.animated #freepik--Sign--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
    animation-delay: 0s;
}

svg#freepik_stories-under-construction.animated #freepik--Paint--inject-19 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
    animation-delay:
        0s;
}

@keyframes lightSpeedRight {
    from {
        transform: translate3d(50%, 0, 0) skewX(-20deg);
        opacity: 0;
    }

    60% {
        transform: skewX(10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(-2deg);
    }

    to {
        opacity: 1;
        transform:
            translate3d(0, 0, 0);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity:
            1;
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: inherit;
    }
}