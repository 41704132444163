
.header {
    display: flex;
    justify-content: space-between;

    &__wrapper {
        display: flex;
    }

    &__user {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

}