
.logo {
    display: flex;
    align-items: center;

    &__icon {
        width: 30px;
    }

    &__title {
        font-size: 15px;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;

    }

    &__sub_title {
        color: #F59337;
    }

}

.logo__auth {
    position: absolute;
    width: 100%;
    justify-content: center;
}
