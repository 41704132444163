.search_dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: block;
    margin-right: 7px;
}

.search_user {
    display: flex;
    align-items: center;
}